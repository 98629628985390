.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 500px;
  min-width: max-content;
}

.fieldset {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 10px;
}

.box {
  display: flex;
  gap: 20px;
}

@media screen and (max-width: 550px) {
  .form {
    width: 100%;
    min-width: 100%;
  }

  .fieldset {
    padding: 10px;
  }
}
