.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: none;
  gap: 20px 30px;
  justify-items: center;
  padding: 0;
  margin: 0;
}

.input {
  min-width: 300px;
}

.edit input {
  background: url('../../images/edit.svg') transparent no-repeat 98%;
}

.error {
  color: var(--color-cart);
  grid-column: 1/3;
  text-align: center;
}

.label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-link);
}

.label input {
  color: var(--color-text);
}

.option {
  grid-column: 1/3;
  justify-self: flex-start;
}

@media screen and (max-width: 860px) {
  .form {
    column-gap: 20px;
  }
}

@media screen and (max-width: 660px) {
  .container {
    padding-left: 0;
  }

  .form {
    grid-template-columns: 1fr;
  }

  .error {
    grid-column: 1/2;
    font-size: 12px;
  }

  .option {
    grid-column: 1/2;
    justify-self: center;
  }
}
