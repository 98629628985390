.contacts {
  display: flex;
  padding-bottom: 10px;
  padding-top: 10px;
  gap: 40px
}

.iframe {
  width: 75vw;
  height: 45vw;
}

@media all and (max-width: 1000px) {
  .contacts {
    flex-direction: column;
    gap: 10px;
  }

  .iframe {
    align-self: center;
    width: 95vw;
    height: 55vw;
  }
}

@media all and (max-width: 500px) {
  .iframe {
    height: 80vw;
  }
}