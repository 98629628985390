.textarea {
  border: 1px solid var(--color-hr);
  box-sizing: border-box;
  padding: 10px 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  font-family: inherit;
}

.form {
  max-width: 550px;
  width: 100%;
  align-self: center;
}

.fieldset {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 15px;
}

.filter {
  display: flex;
  align-items: center;
  gap: 20px;
}

.search {
  border-color: var(--color-border);
}

.select {
  text-align: right;
}

.label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-link);
  gap: 5px;
}

.label input {
  color: var(--color-text);
}

@media screen and (max-width: 700px) {
  .fieldset {
    padding-left: 0;
    padding-right: 0;
  }

  .filter {
    flex-direction: column;
  }

  .label {
    font-size: 14px;
  }
}

