.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.box {
  display: flex;
  gap: 20px;
  width: max-content;
  align-items: flex-end;
}

.button {
  align-self: flex-start;
  min-width: max-content;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.textarea {
  border: 1px solid var(--color-hr);
  box-sizing: border-box;
  padding: 10px 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  font-family: inherit;
}

.error {
  color: var(--color-cart);
}

.span {
  color: var(--color-link);
  font-weight: 500;
}

.item {
  display: grid;
  grid-template-columns: 50% 50%;
  border: 1px solid var(--color-question);
  border-radius: 4px;
  row-gap: 15px;
  padding: 10px;
  justify-items: center;
  width: 200px;
  box-sizing: border-box;
}

.title {
  font-size: 18px;
}

.grid_item {
  grid-column: 1/3;
}

.comment {
  width: 100%;
  grid-column: 1/3;
  text-align: center;
}

.text {
  font-weight: 500;
  text-align: center;
}

.label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-link);
}

.label input {
  color: var(--color-text);
}

@media screen and (max-width: 880px) {
  .content {
    align-items: center;
  }
  .box {
    align-self: center;
  }

  .button {
    align-self: center;
  }

  .list {
    justify-content: center;
  }
}

@media screen and (max-width: 700px) {
  .select {
    flex-direction: column;
    align-items: flex-start;
  }

  .select select {
    min-width: 100%;
  }

  .point {
    font-size: 14px;
    text-align: center;
  }

  .modal_title {
    font-size: 18px;
  }
}

@media screen and (max-width: 600px) {
  .list {
    gap: 5px;
    width: 100%;
  }

  .item {
    max-width: 200px;
    width: calc((100% - 5px)/2);
  }

  .title {
    font-size: 14px;
  }

  .text {
    margin: 0;
    font-size: 12px;
  }

  .comment {
    font-size: 12px;
  }


}

@media screen and (max-width: 500px) {
  .modal {
    min-width: calc(100vw - 20px);
  }
}

@media screen and (max-width: 450px) {
  .box_select {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .modal_title {
    font-size: 14px;
  }
}