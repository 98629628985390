.form {
  width: 100%;
}

.border {
  border: none;
  padding: 0;
}

.fieldset {
  width: 100%;
  padding: 10px 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;


  box-sizing: border-box;
}

.legend {
  color: var(--color-link);
  margin-left: 20px;
  padding-left: 5px;
  padding-right: 5px;
}

@media screen and (max-width: 500px) {
  .legend {
    font-size: 14px;
  }
}
