.container {
  position: relative;
}

.list {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 60px;
  padding: 26px 50px;
  background-color: var(--color-background);
  border: 0.25px solid rgba(41, 41, 41, 0.13);
  box-sizing: border-box;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
              0px 2px 6px rgba(0, 0, 0, 0.04),
              0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 0 6px 6px 0;
  z-index: 2;
  transition: all 0.3s ease-in-out;
}

.link {
  color: inherit;
  font-size: 18px;
  line-height: 129%;
  font-weight: 500;
  transition: opacity .3s linear;
  position: relative;
}

.link:hover {
  opacity: .75;
}

.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.16;
  margin: 0;
  transition: color .3s linear;
}

.title:hover {
  color: var(--color-hover);
}

@media all and (max-width: 780px) {
  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.16;
  }
}

@media all and (max-width: 500px) {
  .title {
    font-size: 16px;
  }

  .link {
    font-size: 16px;
  }
}