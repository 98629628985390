.form {
  min-width: min-content;
  width: 100%;
  align-self: center;
  max-width: 600px;
}

.fieldset {
  width: 100%;
  padding: 10px 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.error {
  color: var(--color-cart);
}

.label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-link);
}

.label input {
  color: var(--color-text);
  width: 290px;
}

.comment {
  font-size: 14px;
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;
  opacity: .8;

}

.select {
  margin-top: 20px;
}

.input {
  width: 260px;
}

.box {
  display: flex;
  gap: 20px;
}

.button {
  align-self: flex-end;
  padding-left: 10px;
  padding-right: 10px;
  background-color: inherit;
}

.button:disabled {
  border: none;
  color: var(--color-disabled);
  cursor: auto;
}

.order {
  color: inherit;
  border-color: var(--color-hr);
  background-color: var(--color-border-categories);
  min-width: 105px;
}

.info {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.span {
  color: var(--color-link);
}

@media all and (max-width: 700px) {
  .info {
    font-size: 14px;
  }
}

@media all and (max-width: 660px) {
  .fieldset {
    box-sizing: border-box;
  }

  .label {
    width: 90vw;
    max-width: 260px;
  }

  .select {
    flex-direction: column;
  }
}

@media all and (max-width: 560px) {
  .info {
    font-size: 14px;
  }
}
