.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  font-size: 22px;
  font-weight: 600;
  align-self: flex-start;
  margin-left: 0;
  padding-left: 15px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.button {
  align-self: flex-end;
  padding-left: 10px;
  padding-right: 10px;
  background-color: inherit;
}

.button:disabled {
  border: none;
  color: var(--color-disabled);
  cursor: auto;
}

.clear {
  color: var(--color-cart);
  border-color: var(--color-cart);
}

.amount {
  font-weight: 600;
  align-self: flex-end;
}

.discount {
  align-self: flex-end;
  font-style: italic;
}

.text {
  padding-left: 15px;
}

.text_order {
  margin-bottom: 10px;
  text-align: center;
}

.link {
  color: var(--color-text);
  font-weight: 600;
  font-size: 18px;
  transition: color .3s linear;
}

.link:hover {
  color: var(--color-hover);
}


@media all and (max-width: 890px) {
  .text {
    padding: 0;
    font-size: 16px;
  }
}

@media all and (max-width: 760px) {
  .title {
    font-size: 18px;
  }

  .amount {
    font-size: 14px;
  }

  .link {
    font-size: 18px;
  }
}

@media all and (max-width: 560px) {
  .list {
    gap: 5px;
  }

  .text {
    font-size: 14px;
  }

  .text_order {
    white-space: pre-line;
  }

  .link {
    font-size: 16px;
  }
}