.header {
  min-height: 54px;
  background-color: var(--color-background);
  padding-bottom: 1px;
  position: sticky;
  top: 0;
  z-index: 5;
}

.container {
  min-height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 20px var(--container-padding) 0;
}

.button {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 20px;
  position: relative;
  z-index: 10;
  background-color: inherit;
  border: none;
}

.line {
  width: 24px;
  margin-bottom: 4px;
  border-bottom: 4px solid var(--color-text);
  border-radius: 4px;
  transition: all 0.3s linear;
  transform-origin: 1px;
}
