.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
}

.content {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  gap: 20px;
}

.form {
  margin-bottom: 20px;
}

.input {
  width: 270px;
  padding-right: 38px;
}

.item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.item:first-of-type {
  margin-top: 20px;
}

.link {
  color: var(--color-link);
}

.button {
  border-radius: 6px;
  min-width: 120px;
  padding: 0 10px;
}

.save {
  color: var(--color-link);
  border-color: var(--color-link);
  box-shadow: 0 0 7px var(--color-link);
}

.save:disabled {
  border: none;
  box-shadow: none;
  color: var(--color-disabled);
}

.message {
  text-align: center;
  font-size: 14px;
  color: var(--color-hr);
  margin-top: 0;
  padding: 0 10px;
}

.error {
  color: var(--color-cart);
}

.text {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.modal {
  padding: 20px;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .column {
    flex-direction: column;
  }

  .text {
    margin: 0;
    font-size: 12px;
  }

  .link {
    font-size: 13px;
  }

  .message {
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
