.content {
  position: relative;
  display: block;
  min-height: 430px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  transition: opacity .3s ease-in-out;
}

.card {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  transition: opacity .3s ease-in-out;
  padding: 0;
  text-decoration: none;
  color: inherit;
}

.image {
  margin: 0;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  width: 100%;
  align-items: flex-start;
  border-radius: 5px;
  height: 297px;
}

.description {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: repeat(4, min-content);
  width: 100%;
  box-sizing: border-box;
  padding: 5px 10px 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #e8e8e8;
  opacity: .8;
  gap: 10px;
  min-height: 133px;
}

.title {
  grid-column: 1/3;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.caption {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 19px;
}

.text {
  font-size: 14px;
  font-weight: 600;
}

.price {
  grid-row: 4/5;
}

.button {
  grid-row: 4/5;
  width: 95%;
}

.button_active {
  background-color: var(--color-background);
  color: var(--color-hover);
  border-color: var(--color-hover);
  box-shadow: 0 0 7px var(--color-hover);
}

.button:hover {
  transform: scale(1.05, 1.05);
}

.button:disabled {
  border: none;
  color: var(--color-disabled);
  cursor: auto;
}

.button:disabled:hover {
  transform: none;
}

@media all and (max-width: 610px) {
  .content {
    min-height: 300px;
  }

  .image {
    height: 198px;
  }

  .description {
    padding: 5px;
    min-height: 102px;
    gap: 5px;
  }

  .card {
    width: 46vw;
  }

  .caption {
    font-size: 12px;
    min-height: 15px;
  }

  .stock {
    font-size: 12px;
  }

  .button {
    font-size: 15px;
  }
}

@media all and (max-width: 460px) {
  .image {
    height: 176px;
  }

  .description {
    justify-items: center;
    min-height: 124px;
  }

  .stock {
    grid-column: 1/3;
  }

  .button {
    grid-column: 1/3;
    grid-row: 5/6;
  }

  .score {
    grid-column: 1/2;
    grid-row: 4/5;
  }
}
