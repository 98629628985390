.container {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 5px;
  max-height: 32px;
  justify-content: space-between;
  border: 1px solid var(--color-hr);
  width: 400px;
  max-width: 100%;
}

.input {
  border: none;
  outline: none;
  font-size: 16px;
  margin: 0 5px;
  width: 100%;
}

.box {
  background: #FFF;
  border: 1px solid rgba(98, 98, 98, 0.2);
  box-sizing: border-box;
  max-height: 150px;
  overflow-y: scroll;
  padding: 10px;
  font-size: 14px;
  min-width: 220px;
  text-align: left;
  cursor: pointer;
  list-style-type: none;
}

.item {
  margin: 8px 0;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}

.title {
  text-align: left;
  font-weight: 500;
  margin-left: 0;
  margin-right: 0;
}

@media screen and (max-width: 500px) {
  .container {
    width: 100%;
    box-sizing: border-box;
  }

  .input {
    font-size: 14px;
    padding: 5px;
  }
}