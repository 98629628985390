.box {
  display: flex;
  align-items: center;
  color: var(--color-hover);
  justify-content: center;
}

.button {
  background-color: inherit;
  border: none;
  padding-left: 5px;
  padding-right: 5px;
}

.text {
  padding: 0;
  margin: 0;
  font-size: 24px;
}

.input {
  width: 50px;
  height: 32px;
  box-sizing: border-box;
  border: none;
  text-align: center;
  font-size: 16px;
}

@media all and (max-width: 560px) {
  .input {
    font-size: 14px;
  }
}