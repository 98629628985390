@font-face {
  src: url(./Inter/Inter-Black.woff2) format('woff2'),
    url(./Inter/Inter-Black.woff) format('woff');
  font-family: 'Inter';
  font-weight: 900;
}

@font-face {
  src: url(./Inter/Inter-Medium.woff2) format('woff2'),
    url(./Inter/Inter-Medium.woff) format('woff');
  font-family: 'Inter';
  font-weight: 500;
}

@font-face {
  src: url(./Inter/Inter-Regular.woff2) format('woff2'),
    url(./Inter/Inter-Regular.woff) format('woff');
  font-family: 'Inter';
  font-weight: normal;
}

@font-face {
  src: url(./Inter/Inter-Bold.woff2) format('woff2'),
    url(./Inter/Inter-Bold.woff) format('woff');
  font-family: 'Inter';
  font-weight: 700;
}