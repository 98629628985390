.section {
  width: calc(100% - var(--container-padding));
  margin: 10px auto;
}

.title {
  margin-bottom: 20px;
}

.text {
  line-height: 26px;
  font-size: 18px;
  font-weight: normal;
  margin: 20px auto 0;
  text-align: justify;
  text-indent: 20px;
}

.text:first-of-type {
  margin: 0 auto;
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.iframe {
  width: 500px;
  height: 300px;
  border: 0;
}

@media all and (max-width: 1064px) {
  .iframe {
    width: 400px;
    height: 250px;
  }
}

@media all and (max-width: 852px) {
  .iframe {
    width: 320px;
    height: 200px;
  }
}

@media all and (max-width: 610px) {
  .title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .text {
    font-size: 16px;
    line-height: 22px;
  }

  .list {
    gap: 5px;
  }
}