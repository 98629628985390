.image {
  width: 22px;
  height: 22px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.cart {
  position: absolute;
  right: 0;
  top: -16px;
  min-width: 14px;
  height: 14px;
  border-radius: 50%;
  color: var(--color-cart);
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  line-height: 15px;
}

.notification {
  width: auto;
}

.count {
  right: -5px;
}

.logo {
  height: 40px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
}

@media all and (max-width: 780px) {
  .cart {
    top: -16px;
  }

  .logo {
    height: 35px;
  }
}