.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.button {
  align-self: flex-end;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.item {
  border-top: 1px solid var(--color-hr);
  border-bottom: 1px solid var(--color-hr);
  padding-top: 5px;
  padding-bottom: 5px;
}


.container {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: min-content 1fr 200px 25px;
  grid-auto-flow: column;
  justify-content: space-between;
  justify-items: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.image {
  height: 80px;
  width: 200px;
  border: 1px solid var(--color-border-categories);
  border-radius: 4px;
  padding: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  grid-row: 1/3;
}

.title {
  margin-left: 0;
  margin-right: 0;
  text-align: left;
}

.text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.end {
  justify-self: flex-end;
}

.form {
  max-width: 400px;
  align-self: center;
}

.fieldset {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 15px;
}

.textarea {
  border: 1px solid var(--color-hr);
  box-sizing: border-box;
  padding: 10px 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  font-family: inherit;
  width: 90%;
}

@media screen and (max-width: 1000px) {
  .image {
    width: 180px;
  }
}

@media screen and (max-width: 700px) {
  .container {
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: min-content 1fr 25px;
    row-gap: 5px;
  }

  .image {
    grid-row: 1/5;
    max-width: 150px;
    width: 30vw;
    height: auto;
  }

  .end {
    grid-row-start: span 2;
  }
}

@media screen and (max-width: 660px) {
  .container {
    font-size: 14px;
  }

  .fieldset {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    box-sizing: border-box;
  }

  .textarea {
    font-size: 14px;
  }
}

@media screen and (max-width: 500px) {
  .container {
    column-gap: 5px;
  }

  .title {
    grid-column: 1/3;
    grid-row: 1/2;
  }

  .text {
    grid-column: 1/4;
    grid-row: 4/5;
  }

  .image {
    grid-row: 2/4;
    width: 25vw;
  }

  .end {
    grid-row-start: span 1;
  }
}