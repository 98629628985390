.address {
  font-style: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.address_column {
  font-style: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.title {
  text-align: left;
  margin: 10px 0 0;
  font-weight: 700;
}

.link {
  position: relative;
  padding-left: 24px;
}

.link::before {
  content: '';
  background-repeat: no-repeat;
  background-position: left center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 15px 15px;
}

.tel::before {
  background-image: url('../../images/telephon.svg');
}

.text {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
}

.mail::before {
  background-image: url('../../images/mail.svg');
}

.map::before {
  background-image: url('../../images/map.svg');
}

@media all and (max-width: 960px) {
  .address {
    flex-direction: column;
    gap: 14px;
    align-items: flex-start;
    margin-bottom: 10px;
  }
}


@media all and (max-width: 500px) {
  .title {
    font-size: 14px;
    font-weight: 600;
  }

  .text {
    font-size: 12px;
  }
}