.content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  width: 100%;
}

.box_button {
  display: flex;
  gap: 10px;
}

.button {
  padding-left: 20px;
  padding-right: 20px;
}

.button:disabled {
  box-shadow: none;
  color: var(--color-disabled);
  cursor: auto;
}

.error {
  color: var(--color-cart);
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-column: 2/3;
}

.fieldset {
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.area {
  font-family: 'Inter', Arial, sans-serif;
  width: 600px;
  box-sizing: border-box;
  border: 1px solid var(--color-disabled);
  padding: 5px;
  font-size: 14px;
  line-height: 1.21;
  font-weight: normal;
  vertical-align: baseline;
  margin-bottom: 20px;
}

.area:focus {
  outline-color: #fff6c5;
}

.area::placeholder {
  font-size: 14px;
  font-family: 'Inter', Arial, sans-serif;
  line-height: 1.21;
  font-weight: normal;
  color: #000;
}

.area::-webkit-input-placeholder {
  font-size: 14px;
  font-family: 'Inter', Arial, sans-serif;
  line-height: 1.21;
  font-weight: normal;
  color: #000;
}

.area::-moz-placeholder {
  font-size: 14px;
  font-family: 'Inter', Arial, sans-serif;
  line-height: 1.21;
  font-weight: normal;
  color: #000;
}

.area::-ms-input-placeholder {
  font-size: 14px;
  font-family: 'Inter', Arial, sans-serif;
  line-height: 1.21;
  font-weight: normal;
  color: #000;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
}

.item {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: space-between;
  width: 100%;
}

.name {
  margin: 0;
  background-color: var(--color-question);
  padding: 5px;
  text-align: left;
}

.date {
  text-align: right;
  background-color: var(--color-question);
  padding: 5px;
  font-size: 14px;
}

.discription {
  grid-column: 1/3;
  text-align: right;
  font-style: italic;
  font-size: 14px;
}

.text {
  grid-column: 1/3;
  text-align: justify;
}

.question {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
}

.answer {
  padding-left: 50px;
  position: relative;
  padding-bottom: 10px;
}

.answer::before {
  content: '';
  background-image: url('../../images/answer.svg');
  background-repeat: no-repeat;
  background-position: left center;
  position: absolute;
  top: -5px;
  left: 20px;
  width: 100%;
  height: 100%;
  background-size: 22px 22px;
}

.box {
  grid-column: 2/3;
  justify-self: flex-end;
  display: flex;
  gap: 10px;
}

.button_admin {
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--color-background);
  border-color: var(--color-question);
  color: var(--color-link);
}

.score {
  margin-bottom: 20px;
}

.star {
  font-size: 50px;
  background: inherit;
  margin-left: 10px;
  color: var(--color-cart);
}

.star:first-of-type {
  margin-left: 0;
}

@media all and (max-width: 930px) {
  .form {
    grid-column: 1/3;
  }

  .fieldset {
    width: 100%;
  }

  .box {
    grid-column: 1/3;
  }

  .area {
    width: 100%;
  }
}

@media all and (max-width: 500px) {
  .box_button {
    align-self: center;
  }

  .name {
    font-size: 14px;
  }

  .date {
    font-size: 12px;
  }

  .discription {
    font-size: 12px;
  }

  .text {
    font-size: 14px;
  }

  .question {
    padding-left: 0;
  }

  .answer {
    padding-left: 40px;
  }
  
  .answer::before {
    left: 10px;
  }
}