.nav {
  width: 100%;
  margin: 0;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--color-hover);
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  z-index: 5;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.link {
  color: inherit;
  font-size: 18px;
  line-height: 129%;
  font-weight: 500;
  transition: opacity .3s linear;
  position: relative;
}

.link:hover {
  opacity: .75;
}

.link_hide {
  display: none;
}

.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.16;
  margin: 0;
  transition: color .3s linear;
}

.title:hover {
  color: var(--color-hover);
}

.list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  gap: 30px;
}

.container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border-radius: 6px;
  padding: 5px;
  max-height: 32px;
  justify-content: space-between;
  border: .5px solid;
  transition: border-color .3s ease-in-out;
}

.input {
  border: none;
  width: 100%;
  outline: none;
  font-size: 16px;
  margin: 0 5px;
  min-width: 250px;
  display: block;
}

.button {
  display: flex;
  transition: opacity 1s ease-in-out;
}

.button_hide {
  display: none;
  transition: opacity .3s ease-in-out;
}

.image {
  display: none;
  height: 34px;
}

@media all and (max-width: 890px) {
  .list {
    gap: 20px;
  }

  .nav {
    padding: 0;
  }

  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.16;
  }
}

@media all and (max-width: 780px) {
  .title {
    display: none;
  }
  
  .image {
    display: block;
  }

  .hide {
    display: none;
  }
}

@media all and (max-width: 530px) {
  .list {
    gap: 10px;
  }

  .image_hide {
    display: none;
  }
}