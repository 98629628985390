.card {
  height: 352px;
  width: 100%;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  color: inherit;
  transition: transform .3s ease-in-out;
}

.card:hover {
  transform: scale(1.05, 1.05);
}

.select {
  min-height: 30px;
  text-align: center;
}

.link_card {
  text-align: center;
  color: var(--color-text);
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  border-radius: 5px;
}

.link_select {
  background-color: var(--color-background);
  width: 170px;
  min-height: 30px;
  border-radius: 3px;
  border: 1px solid var(--color-hr);
  cursor: pointer;
  transition: transform .3s ease-in-out;
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link_select:hover {
  transform: scale(1.05, 1.05);
}

.caption {
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.1;
  text-align: center;
  min-height: 70px;
  background-color: var(--color-caption);
  opacity: .8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 5px 5px;
}

.active {
  font-weight: 500;
  background-color: var(--color-caption);
  transform: scale(1.05, 1.05);
}

@media all and (max-width: 800px) {
  .select {
    margin-bottom: 10px;
  }

  .link_select {
    border: none;
    width: 100%;
    font-size: 14px;
  }

  .active {
    background-color: var(--color-background);
    font-weight: 600;
    transform: none;
  }
}

@media all and (max-width: 600px) {
  .card {
    height: 61vw;
  }

  .caption {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.1;
    min-height: 36px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}