.textarea {
  border: 1px solid var(--color-hr);
  box-sizing: border-box;
  padding: 10px 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  font-family: inherit;
}

.form {
  max-width: 550px;
  width: 100%;
  align-self: center;
}
.fieldset {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 15px;
}

.image {
  height: 75px;
  width: auto;
  border: 1px solid var(--color-border-categories);
  border-radius: 4px;
  padding: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-link);
  gap: 5px;
}

.placeholder input {
  color: var(--color-text);
}

.error {
  color: var(--color-cart);
}

.label {
  display: flex;
  gap: 10px;
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.box {
  display: flex;
  gap: 20px;
}

@media (max-width: 700px) {
  .fieldset {
    padding-left: 0;
    padding-right: 0;
  }

  .placeholder {
    font-size: 14px;
  }

  .label {
    font-size: 14px;
  }
}