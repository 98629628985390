.container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 1440px;
  margin: 0 0 auto 0;
  padding: 60px var(--container-padding);
  box-sizing: border-box;
}

.navigation {
  min-width: 170px;
  width: max-content;
  padding-left: 15px;
}

.list {
  display: grid;
  grid-template-rows: repeat(5, 50px);
  align-items: center;
  grid-auto-rows: 50px;
}

.link {
  cursor: pointer;
  color: inherit;
}

.link_active {
  color: var(--color-link);
  font-weight: 600;
}

.link_exit {
  color: var(--color-cart);
}

@media screen and (max-width: 880px) {
  .container {
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    gap: 20px;
  }

  .navigation {
    width: 100%;
    padding-left: 0;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-left: var(--container-padding);
    margin-right: var(--container-padding);
  }
}

@media screen and (max-width: 660px) {
  .link {
    font-size: 14px;
  }
}

@media screen and (max-width: 460px) {
  .link {
    margin: 0;
  }
}

