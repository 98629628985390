.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  list-style-type: none;
}

@media all and (max-width: 900px) {
  .container {
    margin-left: 0;
  }
}

@media all and (max-width: 800px) {
  .container {
    gap: 0;
    border: 1px solid var(--color-hr);
    width: 170px;
    display: block;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    border-radius: 2px;
  }

  .list {
    width: calc(100% - 2px);
    margin: 0 auto;
    position: absolute;
    top: 30px;
    left: -1px;
    z-index: 1;
    border: 2px solid var(--color-border-categories);
    background-color: var(--color-background);
    display: none;
  }
}