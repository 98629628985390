.modal {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.text {
  text-align: center;
}

.error {
  color: var(--color-cart);
}