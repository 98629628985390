.container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border-radius: 6px;
  padding: 4px 5px;
  max-height: 32px;
  justify-content: space-between;
  border: .5px solid;
  transition: border-color .3s ease-in-out;
}

.input {
  border: none;
  width: 100%;
  outline: none;
  font-size: 16px;
  margin: 0 5px;
  min-width: 250px;
  display: block;
}

.hide {
  display: none;
  transition: opacity .3s ease-in-out;
}

.button {
  display: flex;
  transition: opacity 1s ease-in-out;
}

@media screen and (max-width: 450px) {
  .input {
    min-width: 120px;
  }
}
