.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.button {
  align-self: flex-end;
}

.form {
  width: 400px;
  align-self: center;
}

.fieldset {
  gap: 10px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.item {
  display: flex;
  border-top: 1px solid var(--color-hr);
  border-bottom: 1px solid var(--color-hr);
  justify-content: space-evenly;
  align-items: center;
  gap: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.image {
  height: 75px;
  width: auto;
  border: 1px solid var(--color-border-categories);
  border-radius: 4px;
  padding: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  flex-grow: 2;
}

.title {
  margin: 0;
}

.fieldset {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 15px;
}

.error {
  color: var(--color-cart);
}

.box {
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 500px) {
  .form {
    width: 304px;
  }

  .item {
    padding: 5px;
    gap: 10px;
  }

  .image {
    width: 20%;
    height: auto;
  }

  .container {
    gap: 5px;
  }

  .text {
    font-size: 12px;
  }

  .box {
    flex-direction: column;
    gap: 0;
  }
}
