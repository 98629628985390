.container {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  gap: 10px;
}

.content {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  touch-action: pan-y;
  border-radius: 4px;
}

.list {
  display: flex;
  flex-direction: row;
  margin: 0;
  transition: transform 0.3s ease-out;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.list::-webkit-scrollbar, .list::-webkit-scrollbar {
  display: none;
}

.indicator {
  display: flex;
  justify-content: center;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.indicator::-webkit-scrollbar, .indicator::-webkit-scrollbar {
  display: none;
}

.indicator__item {
  width: 12px;
  height: 12px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: var(--color-indicator);
  cursor: pointer;
  height: 6px;
  width: 6px;
  border-radius: 12px;
  transition-property: all;
  transition-duration: 250ms;
  transition-timing-function: linear;
}

.active {
  background-color: var(--color-active);
  width: 12px;
}

.far {
  width: 4px;
  height: 4px;
  margin-top: 1px;
  margin-bottom: 1px;
}

.button__left, .button__right {
  position: absolute;
  z-index: 1;
  top: calc(50% - 8px);
  transform: translateY(-50%);
  width: 36px;
  height: 48px;
  border-radius: 24px;
  background-color: var(--color-background);
  border: 1px solid var(--color-background);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 150ms linear;
}

.button__left:hover, .button__right:hover {
  opacity: .7;
}

.button__left:focus, .button__right:focus {
  outline: none;
}

.button__left {
  left: 24px;
}

.button__right {
  right: 24px;
}

.left {
  display: block;
  width: 0;
  height: 0;
  border-top: 7.5px solid transparent;
  border-bottom: 7.5px solid transparent;
  border-right:10px solid var(--color-active);
  border-left: 5px solid transparent;
  transform: translateX(-25%);
}

.right {
  display: block;
  width: 0;
  height: 0;
  border-top: 7.5px solid transparent;
  border-bottom: 7.5px solid transparent;
  border-left: 10px solid var(--color-active);
  border-right: 5px solid transparent;
  transform: translateX(25%);
}

@media (hover: none) and (pointer: coarse) {
  .button__left, .button__right {
    display: none;
  }
}

@media all and (max-width: 780px) {
  .container {
    gap: 5px;
  }

  .indicator__item {
    margin-left: 3px;
    margin-right: 3px;
    height: 4px;
    width: 4px;
    border-radius: 6px;
  }

  .active {
    width: 10px;
  }

  .far {
    width: 2px;
    height: 2px;
  }

  .button__left, .button__right {
    top: calc(50% - 6px);
    width: 20px;
    height: 26px;
    border-radius: 13px;
  }

  .button__left {
    left: 10px;
  }
  
  .button__right {
    right: 10px;
  }

  .left {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 7px solid var(--color-active);
    border-left: 3px solid transparent;
  }
  
  .right {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 7px solid var(--color-active);
    border-right: 3px solid transparent;
  }
}
