.error {
  color: var(--color-cart);
}

.span {
  color: var(--color-link);
  font-weight: 500;
}

.text {
  font-weight: 500;
  text-align: center;
}

.label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-link);
}

.label input {
  color: var(--color-text);
}


@media screen and (max-width: 600px) {
  .text {
    margin: 0;
    font-size: 12px;
  }
}

@media screen and (max-width: 450px) {
  .date {
    font-size: 14px;
  }
}