.container {
  width: 100%;
  flex-shrink: 0;
  min-height: 440px;
  color: var(--color-title);
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: var(--color-indicator);
}

.container::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: var(--color-before);
  opacity: .3;
  transition: opacity .2s ease-in-out;
}

@media all and (max-width: 730px) {
  .container {
    min-height: 280px;
  }
}