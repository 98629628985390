* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Arial, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  min-width: 320px;
  max-width: 1440px;

  --color-background: #f9f9f9;

  --color-shadow: rgba(255, 255, 255, 0.05);
  --color-text: #151515;
  --color-hover: #006400;
  --color-hr: rgba(84, 84, 84, 0.7);
  --color-indicator: #ccc;
  --color-active: #777;
  --color-title: #fff;
  --color-before: #2a2c2f;
  --color-cart: #b22222;
  --color-caption: #e8e8e8;
  --color-border: rgba(0, 0, 0, 0.5);
  --color-categories: #f4f4f4;
  --color-border-categories: #e3e3e3;
  --color-question: #a4c4b2;
  --color-button: #cecaca;
  --color-link: #0c555e;
  --color-disabled: rgba(170, 170, 170, 1);

  --container-padding: calc(25%/10);

  background-color: var(--color-background);
  color: var(--color-text);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  background-color: var(--color-background);
  border: none;
  cursor: pointer;
}

input {
  background-color: var(--color-background);
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Arial, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.quill .ql-container {
  width: 500px;
  height: 300px;
  color: var(--color-text);
  font-size: inherit;
}

@media screen and (max-width: 560px) {
  .quill .ql-formats {
    margin-right: 0;
  }

  .quill .ql-container {
    width: 298px;
    height: 200px;
  }

}

