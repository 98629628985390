.label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-link);
}

.label input {
  color: var(--color-text);
}

.error {
  color: var(--color-cart);
}

.box {
  display: flex;
  gap: 20px;
}
