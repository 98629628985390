.container {
  padding: 10px 0 40px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.list {
  display: grid;
  gap: 20px 30px;
  grid-template-columns: repeat(auto-fill, 282px);
  max-width: 1220px;
}

@media screen and (max-width: 1300px) {
  .container {
    max-width: 906px;
  }
}

@media all and (max-width: 960px) {
  .container {
    max-width: 594px;
  }
}

@media all and (max-width: 630px) {
  .list {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}