.container {
  position: relative;
}

.button {
  width: 170px;
  display: none;
  font-size: 14px;
  position: relative;
  background-color: var(--color-categories);
  border-radius: 2px;
  border: none;
}

.button::before {
  content: '';
  background-image: url(../../images/dropdown.svg);
  background-repeat: no-repeat;
  background-position: right 6px center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 10px 40px;
}

.button_up::before {
  background-position: left 6px center;
  transform: rotate(180deg);
}

.list {
  padding-top: 10px;
  width: 100%;
  position: absolute;
  top: 30px;
  left: -1px;
  z-index: 1;
  border: 2px solid #e3e3e3;
  background-color: #f9f9f9;
  display: none;
}

@media all and (max-width: 800px) {
  .button {
    display: flex;
  }

  .list {
    display: block;
  }
}