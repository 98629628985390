.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.box {
  display: flex;
  gap: 22px;
  align-items: center;
}

.status {
  display: flex;
  gap: 22px;
  align-items: center;
}

.right {
  align-self: flex-end;
}

.button {
  padding-left: 10px;
  padding-right: 10px;
  background-color: inherit;
  border-color: var(--color-link);
  color: var(--color-link);
  align-self: flex-end;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.item {
  display: flex;
  border-top: 1px solid var(--color-hr);
  border-bottom: 1px solid var(--color-hr);
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  padding: 10px;
}

.title {
  text-align: left;
  margin-left: 0;
  margin-right: 0;
}

.text {
  position: relative;
  padding-left: 24px;
}

.text::before {
  content: '';
  background-repeat: no-repeat;
  background-position: left center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 18px 18px;
}

.text_optional {
  margin-left: 15px;
}

.text_optional:first-of-type {
  margin-top: 5px;
  margin-bottom: 3px;
}

.mail::before {
  background-image: url('../../images/mail.svg');
}

.tel::before {
  background-image: url('../../images/telephon.svg');
}

.color {
  color: var(--color-cart);
  border-color: var(--color-cart);
}

.empty {
  min-height: 50px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 880px) {
  .empty {
    text-align: center;
  }
}

@media screen and (max-width: 660px) {
  .content {
    gap: 10px;
  }

  .item {
    gap: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .box {
    gap: 10px;
    font-size: 14px;
  }

  .status {
    gap: 10px;
    font-size: 14px;
  }
}

@media screen and (max-width: 500px) {
  .content {
    gap: 10px;
  }

  .item {
    padding-left: 5px;
    padding-right: 5px;
  }

  .box {
    flex-direction: column;
    gap: 5px;
    font-size: 12px;
    text-align: center;
  }

  .status {
    flex-direction: column;
    gap: 5px;
    font-size: 12px;
    text-align: center;
    width: min-content;
    min-width: 39px;
  }

  .text_optional {
    font-size: 14px;
  }
}