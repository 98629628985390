.title {
  text-align: center;
  margin: 0 auto;
}

.title_main {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.16;
}

.title_second {
  font-size: 30px;
  font-weight: 900;
  line-height: 1.46;
  z-index: 2;
  color: var(--color-title);
  position: absolute;
  display: flex;
  min-height: 100%;
  padding: 0 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.title_third {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.1;
}

.title_fourth {
  font-size: 16px;
}

@media all and (max-width: 900px) {
  .title_main {
    font-size: 24px;
    line-height: 1.06;
  }

  .title_second {
    font-size: 28px;
    font-weight: 800;
    line-height: 1.16;
  }
}

@media all and (max-width: 780px) {
  .title_main {
    font-size: 18px;
  }

  .title_second {
    font-size: 22px;
    font-weight: 700;
  }
}

@media all and (max-width: 610px) {
  .title_third {
    font-size: 15px;
    font-weight: 500;
  }

  .title_fourth {
    font-size: 14px;
  }
}

