.container {
  min-width: 440px;
}

.error {
  color: var(--color-cart);
  grid-column: 1/3;
  text-align: center;
}

.text {
  padding: 20px;
}

.label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-link);
}

.label input {
  color: var(--color-text);
  padding-right: 38px;
  width: 100%;
}

@media screen and (max-width: 660px) {
  .container {
    padding-left: 0;
    min-width: 100%;
  }

  .error {
    grid-column: 1/2;
    font-size: 12px;
  }

  .text {
    margin: 0;
    font-size: 12px;
    padding: 0;
  }
}
