.list {
  list-style-type: none;
  margin: 0 auto;
  display: grid;
  gap: 20px 30px;
  grid-template-columns: repeat(auto-fill, 282px);
  list-style-type: none;
  max-width: 1068px;
}

.admin {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.empty {
  width: max-content;
  grid-column: 1/3;
  justify-self: center;
}

.text {
  color: var(--color-cart);
}

@media screen and (max-width: 1220px) {
  .list {
    max-width: 756px;
  }
}

@media all and (max-width: 900px) {
  .list {
    column-gap: 10px;
  }
}

@media all and (max-width: 800px) {
  .list {
    column-gap: 20px;
    padding-left: 0;
  }

  .empty {
    grid-column: 1/3;
    justify-self: center;
  }
}

@media all and (max-width: 610px) {
  .list {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    width: 95vw;
  }

  .text {
    font-size: 14px;
  }
}