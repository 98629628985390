.content {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: min-content 1fr 1fr 1fr 1fr;
  grid-auto-flow: column;
  border-top: 1px solid var(--color-hr);
  border-bottom: 1px solid var(--color-hr);
  justify-items: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.opacity {
  opacity: .5;
}

.form {
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px solid var(--color-hr);
  border-bottom: 1px solid var(--color-hr);
  padding-top: 5px;
  padding-bottom: 5px;
}

.image {
  height: 80px;
  width: auto;

  border: 1px solid var(--color-border-categories);
  border-radius: 4px;
  padding: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 5px;
  margin-bottom: 5px;
  grid-row: 1/3;
}

.title {
  margin-left: 0;
  margin-right: 0;
  text-align: left;
}

.box {
  justify-self: flex-end;
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 700px) {
  .content {
    font-size: 14px;
    gap: 5px;
  }

  .image {
    width: 15vw;
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .content {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: min-content 1fr 1fr min-content;
  }

  .image {
    grid-row: 2/4;
  }

  .category {
    grid-column: 1/2;
  }

  .box {
    flex-direction: column;
    grid-row: 1/3;
  }

}

@media screen and (max-width: 500px) {
  .content {
    font-size: 12px;
  }

  .title {
    font-size: 12px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .category {
    width: 19vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
