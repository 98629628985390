.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.list {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  min-height: 50px;
  align-items: center;
}

.link {
  cursor: pointer;
  color: inherit;
}

.link_active {
  color: var(--color-link);
  font-weight: 600;
}

@media screen and (max-width: 880px) {
  .list {
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .link {
    font-size: 14px;
  }
}