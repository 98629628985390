.item {
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  padding: 10px;
  display: grid;
  grid-template-rows: repeat(2, minmax(60px, min-content));
  grid-template-columns: min-content 1fr max-content 170px min-content;
  grid-auto-flow: column;
  column-gap: 10px;
  row-gap: 5px;
  align-items: center;
  justify-content: space-between;
}

.image {
  display: block;
  height: 130px;
  width: 130px;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  grid-row: 1/3;
}

.title {
  text-align: left;
}

.caption {
  align-self: flex-start;
}

.count {
  text-align: center;
  font-weight: 500;
}

.input {
  color: var(--color-cart);
}

.cost {
  grid-row: 1/3;
  font-weight: 500;
  justify-self: center;
}

.load {
  color: var(--color-active);
  opacity: .7;
}

.stock {
  align-items: center;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.close:disabled {
  display: none;
}

@media all and (max-width: 760px) {
  .item {
    grid-template-rows: repeat(3, min-content);
    grid-template-columns: min-content 1fr max-content min-content;
    column-gap: 10px;
    row-gap: 0;
  }
  
  .image {
    width: 17vw;
    height: 17vw;
    grid-row: 1/4;
  }

  .title {
    font-size: 16px;
    font-weight: 700;
  }

  .caption {
    grid-row: 2/4;
    font-size: 14px;
  }

  .text {
    font-size: 12px;
  }

  .cost {
    font-size: 14px;
    grid-row: 3/4;
  }
}

@media all and (max-width: 560px) {
  .item {
    grid-template-rows: repeat(4, min-content);
    grid-template-columns: 1fr max-content min-content;
    column-gap: 10px;
    row-gap: 5px;
    padding: 5px;
  }

  .image {
    height: 20vw;
    width: 20vw;
    grid-row: 2/4;
  }

  .link {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .title {
    font-size: 14px;
  }

  .caption {
    grid-row: 4/5;
    font-size: 12px;
  }

  .input {
    align-self: flex-end;
  }

  .close {
    grid-row: 1/2
    ;
  }
}