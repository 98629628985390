.button {
  display: none;
  justify-content: center;
  flex-direction: column;
  margin-right: 20px;
  position: relative;
  z-index: 10;
  background-color: inherit;
  border: none;
}

.line {
  width: 24px;
  margin-bottom: 4px;
  border-bottom: 4px solid var(--color-text);
  border-radius: 4px;
  transition: all 0.3s linear;
  transform-origin: 1px;
}

.line_open:first-child {
  transform: rotate(45deg);
}

.line_close:first-child {
  transform: rotate(0);
}

.line_open:nth-child(2) {
  opacity: 0;
}

.line_close:nth-child(2) {
  opacity: 1;
}

.line_open:nth-child(3) {
  transform: rotate(-45deg);
}

.line_close:nth-child(3) {
  transform: rotate(0);
}

@media all and (max-width: 780px) {
  .button {
    display: flex;
  }
}

@media all and (max-width: 540px) {
  .button {
    margin-right: 10px;
  }
}
