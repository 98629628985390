.container {
  position: relative;
  z-index: 10;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 480px;
  padding: 20px;
  background-color: var(--color-background);
  border: 0.25px solid rgba(41, 41, 41, 0.13);
  box-sizing: border-box;
  box-shadow: 0px 16px 24px var(--color-shadow),
              0px 2px 6px var(--color-shadow),
              0px 0px 1px var(--color-shadow);
  border-radius: 16px;
  z-index: 2;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 10px;

  text-align: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
}

.close {
  align-self: flex-end;
}

.error {
  color: var(--color-cart);
}

@media (max-width: 500px) {
  .modal {
    min-width: 300px;
    padding: 10px;
  }

  .title {
    font-size: 16px;
  }
}
