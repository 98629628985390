.box {
  display: flex;
  gap: 30px;
  grid-column: 1/3;
  width: 270px;
  justify-content: center;
}

.button {
  border-radius: 6px;
  min-width: 120px;
}

.save {
  background-color: var(--color-background);
  color: var(--color-link);
  border-color: var(--color-link);
  box-shadow: 0 0 7px var(--color-link);
}

.cancel {
  color: var(--color-link);
  border-color: var(--color-link);
}

.button:disabled {
  box-shadow: none;
  color: var(--color-disabled);
}

@media screen and (max-width: 660px) {
  .box {
    grid-column: 1/2;
  }
}

@media screen and (max-width: 500px) {
  .button {
    padding-left: 0;
  }
}