.container {
  margin: auto;
  padding: 0 var(--container-padding);
  display: flex;
  flex-direction: column;
}

.image {
  width: 100%;
}

.link {
  color: var(--color-text);
  display: flex;
  justify-content: center;
  margin: 40px auto;
  font-weight: 600;
  font-size: 20px;
  transition: color .3s linear;
}

.link:hover {
  color: var(--color-hover);
}

@media all and (max-width: 780px) {
  .link {
    font-size: 18px;
  }
}

@media all and (max-width: 500px) {
  .link {
    font-size: 16px;
  }
}
