.container {
  position: relative;
  width: 270px;
}

.input {
  border: 1px solid var(--color-hr);
  box-sizing: border-box;
  padding: 8px 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  max-height: 40px;
  border-radius: 4px;
  position: relative;
  color: var(--color-text);
}

.icon {
  position: absolute;
  right: 6px;
  bottom: 6px;
}

.edit {
  position: absolute;
  right: 0;
  top: 10px;
  padding-left: 6px;
  padding-right: 6px;
}

.label {
  display: flex;
  gap: 5px;
  align-items: center;
  color: var(--color-link);
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.select {
  padding: 5px;
}

.textarea_label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-link);
  gap: 5px;
  min-width: 260px;
  max-width: 500px;
  width: 90%;
}

.textarea_label textarea {
  color: var(--color-text);
  width: 100%;
}

.textarea {
  border: 1px solid var(--color-hr);
  box-sizing: border-box;
  padding: 10px 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  font-family: inherit;
  min-width: 260px;
  max-width: 500px;
  width: 90%;
}

.box {
  display: flex;
  flex-direction: column;
}

@media (max-width: 700px) {
  .input {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
  }

  .textarea {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
  }

  .textarea_label {
    font-size: 14px;
  }

  .label {
    font-size: 14px;
  }

  .text {
    font-size: 14px;
  }
}