.content {
  width: 100%;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.item {
  display: grid;
  grid-template-columns: 1fr 125px 25px 25px;
  border-top: 1px solid var(--color-hr);
  border-bottom: 1px solid var(--color-hr);
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  padding: 10px;
}

.flex {
  display: flex;
  justify-content: center;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.start {
  justify-self: flex-start;
}

.title {
  text-align: left;
  margin-left: 0;
  margin-right: 0;
}

.text {
  position: relative;
  padding-left: 24px;
}

.text::before {
  content: '';
  background-repeat: no-repeat;
  background-position: left center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 18px 18px;
}

.mail::before {
  background-image: url('../../images/mail.svg');
}

.tel::before {
  background-image: url('../../images/telephon.svg');
}

.active {
  height: 20px;
}

.active::before {
  background-image: url('../../images/active.svg');
}

.active_not::before {
  background-image: url('../../images/delete.svg');
}

.form {
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 400px;
}

.form_box {
  display: flex;
  gap: 20px;
}

.label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-link);
  gap: 5px;
}

.label input {
  color: var(--color-text);
}

@media screen and (max-width: 660px) {
  .item {
    gap: 10px;
  }

  .box {
    font-size: 14px;
  }
}

@media screen and (max-width: 500px) {
  .item {
    grid-template-columns: 1fr 25px;
    grid-template-rows: repeat(3, min-content);
    grid-auto-flow: column;
  }

  .active {
    grid-column: 2/3;
    grid-row: 2/3;
  }

  .end {
    grid-column: 2/3;
    align-self: center;
    justify-self: center;
  }
}