.content {
  display: flex;
  align-items: flex-start;
  padding-top: 1%;
  padding-bottom: 30px;
  gap: 10px;
}

@media all and (max-width: 800px) {
  .content {
    flex-direction: column;
    align-items: flex-end;
    gap: 15px;
  }
}
