.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
}

.form {
  align-self: flex-end;
  width: min-content;
}

.box {
  display: flex;
  gap: 22px;
  align-items: center;
}

.button {
  padding-left: 10px;
  padding-right: 10px;
  background-color: inherit;
  border-color: var(--color-link);
  color: var(--color-link);
  align-self: flex-end;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.item {
  border-top: 1px solid var(--color-hr);
  border-bottom: 1px solid var(--color-hr);
  padding: 5px;
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  font-weight: 600;
  cursor: pointer;
  box-sizing: border-box;
}

.name {
  margin: 0;
  padding: 5px;
  text-align: left;
}

.date {
  justify-self: flex-end;
  padding: 5px;
  font-size: 14px;
}

.text {
  grid-column: 1/3;
  text-align: justify;
  margin: 10px;
}

.link {
  text-decoration: underline;
  margin-left: 15px;
}

.read {
  font-weight: 400;
  cursor: auto;
}

@media screen and (max-width: 610px) {
  .text {
    font-size: 13px;
  }

  .date {
    font-size: 12px;
  }
}
