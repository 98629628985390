.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: inherit;
  box-sizing: border-box;
  margin: 0 auto;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  gap: 70px;
  min-height: 61px;
}

.copy {
  color: var(--color-text);
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  opacity: 0.7;
}

@media all and (max-width: 980px) {
  .footer {
    gap: 30px;
    align-items: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}


@media all and (max-width: 500px) {
  .copy {
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
  }
}