.container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.input {
  display: none;
}

.image {
  height: 75px;
  width: auto;
  border-radius: 4px;
  padding: 5px;
  border: 1px solid var(--color-border-categories);
  object-fit: cover;
  opacity: .5;
}

.button {
  cursor: pointer;
  background: none;
  position: relative;
  transition: opacity .4s linear;
  opacity: 1;
  border: none;
}

.button::before {
  content: '';
  background: url('../../images/edit.svg') center/cover no-repeat;
  position: absolute;
  width: 26px;
  height: 26px;
  display: block;
  position: absolute;
  z-index: 5;
  opacity: .7;
  left: calc(50% - 13px);
  top: calc(50% - 13px);
  transition: opacity .4s linear;
}

@media screen and (max-width: 500px) {
  .container {
    gap: 10px;
  }

  .image {
    width: 50%;
    height: auto;
  }
}