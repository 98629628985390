.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.title {
  font-size: 18px;
  font-weight: 700;
}

.button {
  padding-left: 10px;
  padding-right: 10px;
  background-color: inherit;
}

.cancel {
  color: var(--color-cart);
  border-color: var(--color-cart);
}

.table {
  border-color: var(--color-link);
  color: var(--color-link);

}

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 10px;
}

.text {
  position: relative;
  padding-left: 24px;
}

.text::before {
  content: '';
  background-repeat: no-repeat;
  background-position: left center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 18px 18px;
}

.mail::before {
  background-image: url('../../images/mail.svg');
}

.tel::before {
  background-image: url('../../images/telephon.svg');
}

.italic {
  font-style: italic;
  font-weight: 400;
}

.span {
  padding-left: 10px;
  text-decoration: line-through;
}

.box {
  display: flex;
  gap: 10px;
  align-items: center;
}

.right {
  align-self: flex-end;
  text-align: right;

}

.amount {
  font-weight: 600;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-self: flex-start;
}

.info {
  font-weight: 500;
}

@media all and (max-width: 560px) {
  .title {
    font-size: 16px;
  }

  .box {
    flex-direction: column;
  }

  .info {
    font-size: 14px;
  }

  .italic {
    font-size: 14px;
  }
}
