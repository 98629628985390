.button {
  background-color: var(--color-button);
  min-height: 30px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid var(--color-hr);
  font-size: 16px;
  font-weight: 500;
  transition: transform .3s ease-in-out;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.outline {
  outline: none;
  background: inherit;
}

.image {
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.image_small {
  width: 22px;
}

.button:disabled {
  cursor: auto;
  opacity: .5;
}

.scroll {
  position: fixed;
  width: 100%;
  bottom: 100px;
  left: 10%;
  height: 50px;
  width: 50px;
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-image: url('../../images/up.svg');
  background-color: initial;
}

.eyes {
  position: absolute;
  right: 0;
  bottom: 5px;
  padding-left: 2px;
  padding-right: 2px;
}

@media (max-width: 500px) {
  .button {
    font-size: 14px;
  }

  .image_large {
    width: 30px;
    height: 30px;
  }
}