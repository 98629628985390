.content {
  width: 100%;
}

.image {
  width: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  float: left;
  margin-right: 20px;
  margin-bottom: 10px;
}

.title {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.box {
  display: flex;
  color: var(--color-hr);
}

.color {
  color: var(--color-cart);
}

.caption {
  margin-left: 10px;
}

.container {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: column;
  justify-content: space-between;
}

.text {
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button {
  width: 130px;
  height: 30px;
}

.button:hover {
  transform: scale(1.05, 1.05);
}

.button_active {
  background-color: var(--color-background);
  color: var(--color-hover);
  border-color: var(--color-hover);
  box-shadow: 0 0 7px var(--color-hover);
}

.description {
  text-align: justify;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 400;
}

.divider {
  width: 100%;
}

.inner {
  text-align: justify;
}

.inner ol {
  padding-left: 20px;
}

.inner p {
  margin-top: 15px;
  margin-bottom: 15px;
  text-indent: 20px;
}

.inner ul {
  list-style-type: disc;
  padding-left: 30px;
}

@media all and (max-width: 610px) {
  .title {
    font-weight: 700;
  }
}


@media all and (max-width: 530px) {
  .content {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 20px;
  }

  .container {
    align-items: center;
    gap: 10px;
  }

  .image {
    grid-row: 2/3;
    float: none;
    margin: 0;
    width: 100%;
  }

  .title {
    grid-row: 0/1;
    font-size: 18px;
    margin-bottom: 0;
  }

  .text {
    margin-top: 0;
    margin-bottom: 0;
  }

  .description {
    font-size: 14px;
    margin-top: 0;
  }
}

